<template>
  <CRow class="gap-10" :class="{
    'in-card':farmItemDateCount
  }">
    <CRow class="gap-10">
      <i></i>
      <h4 class="itemFarmArea">{{ farmArea }} هکتار</h4>
      <hr class="w-100"/>
      <div class="bodyItemValue">
        <h6>نام زمین:</h6>
        <h5>
          <span></span>
          <CButton
            size="sm"
            color="secondary"
            class="farmItemBtn btn-View y-center-g-5"
            @click="loadSubFormFarm('farmDetail', `جزئیات زمین ${ title }`)"
          >
            <i class="fa fa-eye"></i>
            <span>{{ title }}</span>
          </CButton>
        </h5>
      </div>
      <div class="bodyItemValue">
        <h6>نوع محصول :</h6>
        <h5>{{ productType }}</h5>
      </div>
      <div class="bodyItemValue">
        <h6>نوع کشت :</h6>
        <h5>{{ cultivationsTypeTitle }}</h5>
      </div>
      <div class="bodyItemValue">
        <h6>مکان زمین :</h6>
        <h5>{{ address }}</h5>
      </div>
    </CRow>
    <hr class="w-100"/>
    <CRow class="w-100 gap-20" align-horizontal="center" align-vertical="center">

      <CButton
        size="sm"
        color="primary"
        class="farmItemBtn btn-AddDate y-center-g-5"
        @click="loadSubFormFarm('farmDate', `${title} - ${productType}`)"
      >
        <i class="fa fa-plus"></i>
        <span>خرید خدمات</span>
        <label class="farmItemDateCount" v-if="farmItemDateCount">
          <span class="small">تعداد ماه: </span>
          <span>{{ farmItemDateCount }}</span>
        </label>
      </CButton>
    </CRow>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      :in-class="`animate__fadeInDown`"
      :out-class="`animate__fadeOutDown`"
      modelStyle="width:60vw;height:60vw;max-width:60vw !important"
    >
      <component
        v-if="subForm != null"
        v-bind:is="subForm"
        :farmId="id"
        :farmTitle="title"
        :farmAddress="address"
        :farmProduct="productType"
        :farmArea="farmAreaReal"
        :dateTimes="farmDateTimes"
        @passDateTimes="getFarmDateTimeSelected"
      />
    </VueModal>
  </CRow>
</template>

<script>
import farmDetail from "./farmDetail.vue";
import farmDate from "./farmDate.vue";

export default {
  name: "farmItem",
  props: {
    itemModel: {
      type: Object,
    },
  },
  components: {
    farmDetail,
    farmDate,
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      modelShowState: false,
      modalTitle: "",
      subForm: null,

      id: 0,
      title: "",
      productType: "",
      cultivationsType: "",
      cultivationsTypeTitle : "",
      address: "",
      canEdit: true,
      canDelete: true,
      farmArea: 0,
      farmItemDateCount: 0,
      farmAreaReal: 0,
      farmDateTimes: [],
    };
  },
  methods: {
    fetchData() {
      console.log("this.itemModel",this.itemModel);
      this.id = this.itemModel.farmId;
      this.title = this.itemModel.title;
      this.productType = this.itemModel.product;
      this.cultivationsType = this.itemModel.cultivationsType;
      this.cultivationsTypeTitle = this.itemModel.cultivationsTypeTitle;
      this.address = this.itemModel.address;
      this.canEdit = this.itemModel.canEdit;
      this.canDelete = this.itemModel.canDelete;
      this.farmArea = this.itemModel.farmArea;
      this.farmAreaReal = this.itemModel.farmAreaReal;
    },
    loadSubFormFarm(subFormName, subFormTitle) {
      this.subForm = subFormName;
      this.modalTitle = subFormTitle;
      this.modelShowState = true;
    },
    getFarmDateTimeSelected(farmModel) {
      if (farmModel) {
        this.farmItemDateCount = farmModel.dateTimes.length;
        this.farmDateTimes = farmModel.dateTimes;
        this.subForm = null;
        this.modalTitle = null;
        this.modelShowState = false;
        this.$emit("farmDateTimeValues", farmModel);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.headerItem {
  width: 100%;
  height: 10rem;
  padding: 5px;
  float: right;
}

.headerItem h3 {
  clear: both;
  float: right;
  width: 100%;
  font-size: 13px;
  text-align: center;
  height: 10px;
}

.headerItem .mapView {
  clear: both;
  float: right;
  width: 100%;
  height: 8rem;
  background-color: #bbb;
}

.headerItem label {
  float: left;
  clear: both;
  width: 100%;
  padding: 0px 5px;
  background-color: #f80;
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.cardSelected {
  background-color: #8679ff !important;
  box-shadow: 0px 8px 8px 3px #8679ff !important;
}


.bodyItemValue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
}

.bodyItemValue h6 {
  font-size: 12px;
  text-wrap: nowrap;
}

.bodyItemValue h5 {
  font-size: 13px;
  text-align: left;
}

.farmItemDateCount {
  background-color: #666;
  padding: 2px 10px;
  height: 23px;
  color: #fff;
  border-radius: 30px;
  margin: 0;
}

.farmItemDateCount h3 {
  float: left;
  color: #fff;
  font-size: 1rem;
}

.itemFarmArea {
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.btn-Edit {
  background-color: rgb(52, 116, 253);
  right: 1%;
}


.btn-DietPlan {
  background-color: rgb(60 71 95);
  left: 14%;
}

.btn-Advice {
  background-color: rgb(60 71 95);
  left: 0%;
}

.btn-Colleagues {
  background-color: rgb(60 71 95);
  left: 2%;
}
.main-card.in-card{
  box-shadow: 0 0 4px 0 var(--secondary-light);
}
</style>
