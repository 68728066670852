<template>
  <div class="main-box">
    <CRow class="info-box gap-10" align-horizontal="evenly">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <CAlert color="success">
        روی دکمه ی افزودن تاریخ کلیک کنید و سپس تاریخ های مورد نظر را برای بررسی ماهواره، انتخاب نمایید.
      </CAlert>
      <CCol md="6" class="base-card allDateTimeBox">
        <CRow>
          <div class="addDateBox">
            <div
              class="selectYearAndMonth"
              v-for="(item, index) in sentinetDates"
              :key="index"
            >
              <i
                class="fa fa-trash"
                aria-hidden="true"
                @click="removeDateTime(index)"
              ></i>
              <select @change="onChangeYear($event)" v-model="item.year">
                <option
                  v-for="(item, index) in years"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
              <select v-model="item.month">
                <option
                  v-for="(item, index) in months"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <CButton block color="primary" size="sm" @click="createDateTimeInput()" class="y-center-g-5 justify-content-center">
              <span>افزودن تاریخ</span>
              <i class="fa fa-plus"></i>
            </CButton>
          </div>
        </CRow>
      </CCol>
      <CCol md="5" class="base-card farmDateTimesList">
        <h4
          v-if="dateList.length == 0"
          style="font-size: 0.9rem; text-align: center; margin-top: 10%"
        >
          هیچ ماهی برای زمین خریداری نشده است
        </h4>
        <div v-else class="p-1 d-flex gap-10 flex-column">
          <div
            class="dateItem y-center-g-10"
            v-for="(item, index) in dateList"
            :key="index"
          >
            <i class="fa fa-calendar" aria-hidden="true"></i>
            <h4>{{ item.year }}</h4>
            <h3>{{ item.month.label }}</h3>
          </div>
        </div>
      </CCol>
      <CCol md="12">
        <CRow align-horizontal="evenly">
          <CButton color="danger" size="sm" @click="removeAllDateTimes()" class="y-center-g-5">
            <i class="fa fa-trash"></i>
            <span>حذف تمام تاریخ ها</span>
          </CButton>
          <CButton color="secondary" size="sm" @click="passFarmDateTimes()" class="y-center-g-5">
            <i class="fa fa-check"></i>
            <span>تایید نهایی تاریخ ها</span>
          </CButton>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script scoped>
import { mapActions } from "vuex";
import storeMonths from "../../../../store/data/month.json";
import storeYears from "../../../../store/data/year.json";
import { getCurrentUser } from "../../../../utils/index";
export default {
  name: "farmDate",
  props: [
    "farmId",
    "farmTitle",
    "farmAddress",
    "farmProduct",
    "farmArea",
    "dateTimes",
  ],
  data() {
    return {
      loading: false,
      loadingText: "",
      dateList: [],
      sentinetDates: [],
      allYear: storeYears,
      allMonth: storeMonths,
      years: [],
      months: [],
    };
  },
  methods: {
    ...mapActions("financialUser", ["SentinelGetFarmAllDateTimes"]),
    async fetchFarm() {
      this.loading = true;
      this.loadingText = "دریافت لیست تاریخ های زمین...";
      var result = await this.SentinelGetFarmAllDateTimes({
        farmId: this.farmId,
      });
      if (result.succeeded) {
        this.years.push({
          value: 0,
          label: "انتخاب سال",
        });
        if (getCurrentUser().type === "Agency")
          this.bindingAccessDateTimes(result);
        else this.bindingDateTimes(result);
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      if (this.dateTimes.length > 0) {
        this.months = this.allMonth;
        this.dateTimes.forEach((element) => {
          this.sentinetDates.push({
            year: element.year,
            month: element.month,
          });
        });
      }
      this.loading = false;
    },
    createDateTimeInput() {
      this.sentinetDates.push({
        year: 0,
        month: 0,
      });
    },
    onChangeYear(event) {
      this.months = [];
      this.months.push({
        label: "انتخاب ماه",
        value: 0,
      });
      if (getCurrentUser().type === "Agency") {
        this.allMonth.forEach((mElement) => {
          if (
            this.dateList.filter(
              (x) =>
                x.year == event.target.value && x.month.value == mElement.value
            ).length > 0
          ) {
            this.months.push({
              label: mElement.label,
              value: mElement.value,
            });
          }
        });
      } else {
        this.allMonth.forEach((mElement) => {
          if (
            this.dateList.filter(
              (x) =>
                x.year == event.target.value && x.month.value == mElement.value
            ).length == 0
          ) {
            this.months.push({
              label: mElement.label,
              value: mElement.value,
            });
          }
        });
      }
    },
    passFarmDateTimes() {
      let resultDateTimes = [];
      this.sentinetDates.forEach((element) => {
        if (element.year != 0 && element.month != 0) {
          resultDateTimes.push(element);
        }
      });
      let resultModel = {
        farmId: this.farmId,
        farmArea: this.farmArea,
        farmTitle: this.farmTitle,
        dateTimes: resultDateTimes,
      };
      this.$emit("passDateTimes", resultModel);
    },
    removeAllDateTimes() {
      this.sentinetDates = [];
    },
    removeDateTime(index) {
      this.sentinetDates.splice(index, 1);
    },
    bindingDateTimes(result) {
      if (result.data != null) {
        result.data.forEach((value, index) => {
          this.dateList.push({
            year: value.year,
            month: this.allMonth.find((x) => x.value == value.month),
          });
        });
        this.allYear.forEach((item, index) => {
          if (this.dateList.filter((x) => x.year == item.value).length < 12) {
            this.years.push({
              value: item.value,
              label: item.label,
            });
          }
        });
      } else {
        this.allYear.forEach((item, index) => {
          this.years.push({
            value: item.value,
            label: item.label,
          });
        });
      }
    },
    bindingAccessDateTimes(result) {
      if (result.data != null) {
        result.data.forEach((value, index) => {
          this.dateList.push({
            year: value.year,
            month: this.allMonth.find((x) => x.value == value.month),
          });
        });
        this.dateList.forEach((value, index) => {
          if (this.years.filter((x) => x.value == value.year).length == 0) {
            this.years.push({
              value: value.year,
              label: value.year,
            });
          }
        });
      } else {
        this.years = [];
      }
    },
  },
  mounted() {
    this.fetchFarm();
  },
};
</script>
<style scoped>

.info-box {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.addDateBox {
  float: right;
  clear: both;
  padding: 9px 3px;
  width: 100%;
  height: 32vh;
}

.dateItem h4 {
  font-weight: 600;
  font-size: 15px;
}
.dateItem h3 {
  font-size: 15px;
}
.dateItem i {
  font-size: 13px;
}
.selectYearAndMonth {
  display: flex;
  width: 100%;
  padding: 5px 0;
}
.selectYearAndMonth select {
  float: right;
  width: 42%;
  margin-right: 3%;
}
.selectYearAndMonth i {
  float: right;
  font-size: 19px;
  margin-top: 4px;
  margin-right: 5px;
  color: red;
  cursor: pointer;
}

.allDateTimeBox {
  border-radius: var(--border-radius);
}

.farmDateTimesList {
  padding: 10px;
  background-color: #76767617;
  border-radius: var(--border-radius);
}
.farmInfoModel label {
  float: right;
  margin-right: 3%;
}
.farmInfoModel label span {
  float: left;
  color: black;
  margin-right: 4px;
}
</style>
