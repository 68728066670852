<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow>
      <div class="farmInfoBox">
        <div class="farmInfoBoxItem">
          <h3>نام</h3>
          <h4>{{ title }}</h4>
        </div>
        <div class="farmInfoBoxItem">
          <h3>محصول (نوع محصول)</h3>
          <h4>{{ productName }} ({{ productType }})</h4>
        </div>
        <div class="farmInfoBoxItem">
          <h3>نوع کشت (تاریخ کشت)</h3>
          <h4>{{ cultivationsTypeTitle }} ({{ cultivationsDate }})</h4>
        </div>
        <div class="farmInfoBoxItem">
          <h3>استان و شهرستان</h3>
          <h4>{{ stateName }} ({{ cityName }})</h4>
        </div>
        <div class="farmInfoBoxItem">
          <h3>بخش و روستا</h3>
          <h4>{{ district }} ({{ village }})</h4>
        </div>
        <div class="farmInfoBoxItem">
          <h3>هکتار</h3>
          <h4>{{ farmArea }}</h4>
        </div>
      </div>
      <div class="farmMapBox"></div>
    </CRow>
  </div>
</template>

  <script>
import { mapActions } from "vuex";

export default {
  name: "farmDetail",
  props: ["farmId", "farmArea"],
  data() {
    return {
      title: null,
      productType: null,
      productName: null,
      cultivationsTypeTitle: null,
      cultivationsDate: null,
      stateName: null,
      cityName: null,
      district: null,
      village: null,
      loading: false,
      loadingText: "",
    };
  },
  components: {},
  methods: {
    ...mapActions("financialUser", ["SentinelGetFarmDetailById"]),
    async fetchFarm() {
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات زمین ... ";
      var result = await this.SentinelGetFarmDetailById({ id: this.farmId });
      if (result.succeeded) {
        this.title = result.data.title;
        this.productType = result.data.productType;
        this.productName = result.data.productName;
        this.cultivationsTypeTitle = result.data.cultivationsTypeTitle;
        this.cultivationsDate = result.data.cultivationsDate;
        this.stateName = result.data.stateName;
        this.cityName = result.data.cityName;
        this.district = result.data.district;
        this.village = result.data.village;
        this.farmArea = this.farmArea;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetchFarm();
  },
};
</script>
  <style scoped>

div.form-control {
  margin-left: 1rem;
}

.farmInfoBox {
  width: 50%;
  height: 41vh;
  float: right;
}
.farmMapBox {
  width: 45%;
  height: 38vh;
  float: right;
  background-color: #f80;
  margin: 2%;
  background-image: url("../../../../assets/img/farmImageMapBox.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius);
}
.farmInfoBoxItem {
  width: 100%;
  float: right;
  clear: both;
  margin-top: 9px;
  height: 54px;
}
.farmInfoBoxItem h3 {
  width: 97%;
  float: right;
  clear: both;
  font-size: 0.8rem;
  margin: 4px;
  text-align: right;
  padding: 3px 8px;
  color: #959595;
}
.farmInfoBoxItem h4 {
  width: 100%;
  float: right;
  clear: both;
  font-size: 0.9rem;
  margin-top: 2px;
  padding-right: 7px;
}
#mapBox {
  width: 100%;
  min-height: 250px;
  background-color: #bbb;
}
</style>
