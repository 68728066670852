<template>
  <CRow class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div v-if="sentinetDates.length > 0" class="farmDataTime">
      <CRow v-for="(item, index) in sentinetDates" :key="index" class="base-card">
        <h5 class="farmTitle">{{ item.farmTitle }} ({{ item.farmArea }})</h5>
        <div
          class="farmDataTimeItem"
          v-for="(dateItem, dateIndex) in item.dateTimes"
          :key="dateIndex"
        >
          <h6 class="p-2">{{ getMothName(dateItem.month) }} {{ dateItem.year }}</h6>
          <span v-if="dateIndex < item.datedateTimes.length">-</span>
        </div>
      </CRow>
    </div>
  </CRow>
</template>

<script>
import storeMonths from "../../../../store/data/month.json";
import storeYears from "../../../../store/data/year.json";

export default {
  name: "farmDate",
  props: ["allInvoiceItems"],
  data() {
    return {
      loading: false,
      loadingText: "",
      sentinetDates: [],
      allYear: storeYears,
      allMonth: storeMonths,
    };
  },
  methods: {
    getMothName(numberMonth) {
      let m = this.allMonth.find((x) => x.value == numberMonth);
      return m.label;
    },
  },
  mounted() {
    this.sentinetDates = this.allInvoiceItems;
  },
};
</script>

<style scoped>
.farmDataTime {
  width: 100%;
  padding: 10px;
  overflow-y: auto;
}
.farmTitle {
  width: 100%;
  font-size: 15px;
  color: #5c5c5c;
  background-color: #eee;
  padding: 8px;
}
.farmDataTimeItem h4 {
  font-size: 17px;
  margin: 4px 2px;
}
.farmDataTimeItem h3 {
  font-size: 17px;
  background: #fff;
  margin: 3px;
  padding: 1px 5px;
}
</style>
