<template>
  <div class="h-100 position-relative">
    <MainBodyHeader title="خرید خدمات ماهواره ای">
      <CRow align-horizontal="end">
        <CCol col="auto">
          <CButton color="primary" class="y-center-g-5" @click="factorState = !factorState">
            <font-awesome-icon icon="shopping-cart"/>
            <span>سبد خرید شما</span>
            <span class="chip bg-white text-primary">{{ numberInCart }} {{ numberInCart > 0 ? "ماه" : "" }}</span>
          </CButton>
        </CCol>
      </CRow>
    </MainBodyHeader>
    <CRow class="body-wrapper buy-service-page w-100">
      <farmListForBuyService
        :factor-state="factorState"
        @showFactor="factorState = false"
        @hideFactor="factorState = false"
        @updateNumberInCart="updateNumberInCart"
        @refreshForm="refreshAllFinancialSubForm"
        ref="reffarmListForBuyService"
      />
    </CRow>
  </div>
</template>

<script>
import farmListForBuyService from "./farmListForBuyService.vue";
import MainBodyHeader from "../../../components/mainHeader.vue";

export default {
  components: {
    MainBodyHeader,
    farmListForBuyService,
  },
  data() {
    return {
      accountId: null,
      buyServiceId: null,
      factorState:true,
      numberInCart:0,
    };
  },
  computed: {},
  methods: {
    updateNumberInCart(count){
      this.numberInCart = count;
    },
    setCurrentAccountId(id) {
      this.accountId = id;
    },
    setCurrentBuyServiceId(id) {
      this.buyServiceId = id;
      this.$refs.reffarmListBuyedService.getBuyServiceDetailListInfo(id);
    },
    async refreshAllFinancialSubForm() {
      await this.$refs.refinvoiceInfo.getLatestSentinelInfo();
      await this.$refs.reffarmListBuyedService.getBuyServiceDetailListInfo(
        this.buyServiceId
      );
    },
    callPaymentPerfixFactor(buyServiceId) {
      this.$refs.reffarmListForBuyService.getPerfixPayBuyServiceInfo(
        buyServiceId
      );
    },
  },
  mounted() {},
  created() {},
};
</script>
<style scoped>

</style>
